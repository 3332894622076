import type { FixedLengthArray } from 'type-fest'
import { z } from 'zod'

import { UnhandledUnionError } from '@mntn-dev/utilities'

import type { ActivityType, ActivityTypes } from './activity-types.ts'
import { AgreementTermsAcceptedActivityDetailsSchema } from './agreement-terms-accepted.ts'
import { CommentCreatedActivityDetailsSchema } from './comment-created.ts'
import { PostProductionCompletedActivityDetailsSchema } from './post-production-completed.ts'
import { PostProductionReviewStatusChangedActivityDetailsSchema } from './post-production-review-status-changed.ts'
import { PreProductionBrandFeedbackSubmittedActivityDetailsSchema } from './pre-production-brand-feedback-submitted.ts'
import { PreProductionCompletedActivityDetailsSchema } from './pre-production-completed.ts'
import { PreProductionMakerProposalSubmittedActivityDetailsSchema } from './pre-production-maker-proposal-submitted.ts'
import { PreProductionReviewStatusChangedActivityDetailsSchema } from './pre-production-review-status-changed.ts'
import { ProjectCompletedActivityDetailsSchema } from './project-completed.ts'
import { ProjectCreatedActivityDetailsSchema } from './project-created.ts'
import { ProjectCustomPriceFinalizedActivityDetailsSchema } from './project-custom-price-finalized.ts'
import { ProjectFileAddedActivityDetailsSchema } from './project-file-added.ts'
import { ProjectInProductionActivityDetailsSchema } from './project-in-production.ts'
import { ProjectMatchedActivityDetailsSchema } from './project-matched.ts'
import { ProjectOfferExpiredActivityDetailsSchema } from './project-offer-expired.ts'
import { ProjectOfferSentActivityDetailsSchema } from './project-offer-sent.ts'
import { ProjectServiceAddedActivityDetailsSchema } from './project-service-added.ts'
import { ProjectSubmittedActivityDetailsSchema } from './project-submitted.ts'
import { ServiceNoteAddedActivityDetailsSchema } from './service-note-added.ts'
import { ProjectNotMatchedActivityDetailsSchema } from './project-not-matched.ts'

/**
 * This function is used to get the activity details schema for a specific activity type.
 * It is used in conjunction with "satisfies FixedLengthArray" to ensure that all activity
 * types are represented in the ActivityDetailsSchema discriminated union.
 */
export const getActivityDetailsSchema = (activityType: ActivityType) => {
  switch (activityType) {
    case 'agreement_terms_accepted': {
      return AgreementTermsAcceptedActivityDetailsSchema
    }
    case 'comment_created': {
      return CommentCreatedActivityDetailsSchema
    }
    case 'post_production_completed': {
      return PostProductionCompletedActivityDetailsSchema
    }
    case 'post_production_review_status_changed': {
      return PostProductionReviewStatusChangedActivityDetailsSchema
    }
    case 'pre_production_brand_feedback_submitted': {
      return PreProductionBrandFeedbackSubmittedActivityDetailsSchema
    }
    case 'pre_production_completed': {
      return PreProductionCompletedActivityDetailsSchema
    }
    case 'pre_production_maker_proposal_submitted': {
      return PreProductionMakerProposalSubmittedActivityDetailsSchema
    }
    case 'pre_production_review_status_changed': {
      return PreProductionReviewStatusChangedActivityDetailsSchema
    }
    case 'project_completed': {
      return ProjectCompletedActivityDetailsSchema
    }
    case 'project_created': {
      return ProjectCreatedActivityDetailsSchema
    }
    case 'project_custom_price_finalized': {
      return ProjectCustomPriceFinalizedActivityDetailsSchema
    }
    case 'project_file_added': {
      return ProjectFileAddedActivityDetailsSchema
    }
    case 'project_in_production': {
      return ProjectInProductionActivityDetailsSchema
    }
    case 'project_matched': {
      return ProjectMatchedActivityDetailsSchema
    }
    case 'project_not_matched': {
      return ProjectNotMatchedActivityDetailsSchema
    }
    case 'project_offer_expired': {
      return ProjectOfferExpiredActivityDetailsSchema
    }
    case 'project_offer_sent': {
      return ProjectOfferSentActivityDetailsSchema
    }
    case 'project_service_added': {
      return ProjectServiceAddedActivityDetailsSchema
    }
    case 'project_submitted': {
      return ProjectSubmittedActivityDetailsSchema
    }
    case 'service_note_added': {
      return ServiceNoteAddedActivityDetailsSchema
    }
    default: {
      throw new UnhandledUnionError(activityType)
    }
  }
}

export const ActivityDetailsSchema = z.discriminatedUnion('activityType', [
  getActivityDetailsSchema('agreement_terms_accepted'),
  getActivityDetailsSchema('comment_created'),
  getActivityDetailsSchema('post_production_completed'),
  getActivityDetailsSchema('post_production_review_status_changed'),
  getActivityDetailsSchema('pre_production_brand_feedback_submitted'),
  getActivityDetailsSchema('pre_production_completed'),
  getActivityDetailsSchema('pre_production_maker_proposal_submitted'),
  getActivityDetailsSchema('pre_production_review_status_changed'),
  getActivityDetailsSchema('project_completed'),
  getActivityDetailsSchema('project_created'),
  getActivityDetailsSchema('project_custom_price_finalized'),
  getActivityDetailsSchema('project_file_added'),
  getActivityDetailsSchema('project_in_production'),
  getActivityDetailsSchema('project_matched'),
  getActivityDetailsSchema('project_not_matched'),
  getActivityDetailsSchema('project_offer_sent'),
  getActivityDetailsSchema('project_offer_expired'),
  getActivityDetailsSchema('project_service_added'),
  getActivityDetailsSchema('project_submitted'),
  getActivityDetailsSchema('service_note_added'),
] satisfies FixedLengthArray<z.AnyZodObject, typeof ActivityTypes.length>)

export * from './activity-data.models.ts'
export * from './activity-types.ts'
export * from './agreement-terms-accepted.ts'
export * from './comment-created.ts'
export * from './post-production-review-status-changed.ts'
export * from './pre-production-brand-feedback-submitted.ts'
export * from './pre-production-completed.ts'
export * from './pre-production-maker-proposal-submitted.ts'
export * from './pre-production-review-status-changed.ts'
export * from './project-completed.ts'
export * from './project-created.ts'
export * from './project-custom-price-finalized.ts'
export * from './project-file-added.ts'
export * from './project-in-production.ts'
export * from './project-matched.ts'
export * from './project-not-matched.ts'
export * from './project-offer-expired.ts'
export * from './project-offer-sent.ts'
export * from './project-service-added.ts'
export * from './service-note-added.ts'
